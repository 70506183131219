import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

import * as CONSTANT_LIST from '../constants/constant-list';


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  protected constantList = CONSTANT_LIST;

  constructor() {}

  // @ts-ignore
  setDataInLocalStorage({ key, value }: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getDataInLocalStorage(key: string): any {
    const item = localStorage?.getItem(key);
    if (item && item !== 'undefined') {
      return JSON.parse(item);
    }
    return null;
  }

  removeDataInLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }

  remove(key: string): void {
    this.removeDataInLocalStorage(key);
  }

  get(key: string): any {
    return this.getDataInLocalStorage(key);
  }

  // @ts-ignore
  set({ key, value }): any {
    this.setDataInLocalStorage({ key, value });
  }

  clearDataInLocalStorage(): any {
    localStorage.clear();
  }

  setToken(token: string): void {
    this.setDataInLocalStorage({
      key: this.constantList.TOKEN,
      value: token
    });
  }

  getToken(): any {
    return this.getDataInLocalStorage(this.constantList.TOKEN);
  }

  getEncryptedData(key: string, secret: any = null): any {
    const item = localStorage?.getItem(key);
    if (item && item !== 'undefined') {
      const bytes = CryptoJS.AES.decrypt(item, secret);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }

    return null;
  }

  // @ts-ignore
  setEncryptData({key, value}, secret: any = null): void {
      localStorage.setItem(key, CryptoJS.AES.encrypt(JSON.stringify(value), secret).toString());
  }
}

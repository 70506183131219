import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class HelperService {
  constructor() {}

  public uploadMedia(event: any): any {
    return new Promise((resolve, reject) => {
      if (event.target.files && event.target.files.length) {
        const media = event.target.files[0];
        const data: any = {
          src: '',
          formData: media,
          type: media.type,
          name: media.name,
        };
        if (media.type.indexOf('image') === -1) {
          data.src = data.formData;
          resolve(data);
        } else {
          const reader = new FileReader();
          const [file] = event.target.files;
          reader.readAsDataURL(file);
          reader.onload = () => {
            data.src = reader.result as string;
            resolve(data);
          };
        }
      } else {
        reject();
      }
    });
  }

  /**
   * The following method is used to convert the respective base64encoded data into a blob
   * @param dataURI
   * @returns {Blob}
   */
  public dataURItoBlob(dataURI: any): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const dataView = new DataView(ab);
    return new Blob([dataView], { type: mimeString });
  }

  /**
   * the following makes sure the given string is not null or empty
   * @param {string} value
   * @returns {boolean}
   */
  public isNotEmptyOrUndefined(value: any) {
    return (value !== undefined || value !== '' || value.length > 0);
  }

  /**
   * The following method is used to convert the image file into Data URI
   * @param imageFile
   * @param callback
   */
  public imageFileToDataURI(imageFile: any, callback: any) {
    const file: File = imageFile,
      myReader: any = new FileReader();

    myReader.onloadend = (e: any) => {
      const trimIndex = myReader?.result.toString().indexOf('base64,');
      callback(myReader?.result.toString().substr(trimIndex + 7));
    };

    myReader.readAsDataURL(file);
  }

  /**
   * The following method is used to convert the image file into Data URI
   * @param base64Img
   * @param callback
   */
  public formatBase64Image(base64Img: string, callback: any) {
    const img = base64Img;
    const trimIndex = img.indexOf('base64,');
    callback(img.substr(trimIndex + 7));
  }

  /**
   * The following method is used to check the dimensions of image
   * @param imageFile
   * @param callback
   */
  public imageFileDimensions(imageFile: any, callback: any) {
    const fReader: any = new FileReader();
    fReader.onload = function () {
      const img = new Image;
      img.onload = function () {
        callback(img.width, img.height);
      };

      img.src = fReader.result.toString();
    };

    fReader.readAsDataURL(imageFile);
  }

  /**
   * The following checks if the given is a valid base64 string or not
   * @param {string} base64Img
   * @returns {boolean}
   */
  public isValidBase64(base64Img: string) {
    try {
      atob(base64Img);
      return true;
    } catch (e) {
      return false;
    }
  }

  public getNestedPropertyValue(
    theObject: any,
    path: string,
    separator = '.'
  ): string {
    try {
      separator = separator || '.';
      const pathsList = path
        .replace('[', separator)
        .replace(']', '')
        .split(separator);
      return pathsList.reduce((obj, property, index) => {
        // Checking for array
        if (property.indexOf('*') > -1) {
          if (Array.isArray(obj)) {
            return obj || [];
          }
          return (obj[pathsList[index - 1]] || []).map((i: any) => {
            return i[pathsList[index + 1]];
          });
        }
        if (Array.isArray(obj)) {
          return (obj).map((i: any) => {
            return i[property];
          });
        }
        return obj[property];
      }, theObject);
    } catch (err) {
      return '';
    }
  }
}

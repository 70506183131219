import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {CanActivateGuard} from "@app/core/guards";
import {LayoutComponent} from "@layout/layout.component";

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/dashboard/dashboard.module')
          .then(mod => mod.DashboardModule)
      },
      {
        path: 'user-management',
        loadChildren: () => import('./views/user-management/user-management.module')
          .then(mod => mod.UserManagementModule)
      },
      {
        path: 'algorithm-management',
        loadChildren: () => import('./views/algorithm-management/algorithm-management.module')
          .then(mod => mod.AlgorithmManagementModule)
      },
      {
        path: 'crm',
        loadChildren: () => import('./views/crm/crm.module')
          .then(mod => mod.CrmModule)
      },
      {
        path: 'projects',
        loadChildren: () => import('./views/projects/projects.module')
          .then(mod => mod.ProjectsModule)
      },
      {
        path: 'datasets',
        loadChildren: () => import('./views/datasets/datasets.module')
          .then(mod => mod.DatasetsModule)
      },
      {
        path: 'tool-configurations',
        loadChildren: () => import('./views/tool-configurations/tool-configurations.module')
          .then(mod => mod.ToolConfigurationsModule)
      },
      {
        path: 'rates-bundles',
        loadChildren: () => import('./views/rates-bundles/rates-bundles.module')
          .then(mod => mod.RatesBundlesModule)
      },
      {
        path: 'web-configurations',
        loadChildren: () => import('./views/web-configurations/web-configurations.module')
          .then(mod => mod.WebConfigurationsModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module')
          .then(mod => mod.ProfileModule)
      },
      {
        path: 'transactions',
        loadChildren: () => import('./views/transactions/transactions.module')
          .then(mod => mod.TransactionsModule)
      },
    ],
    canActivate: [CanActivateGuard],
    runGuardsAndResolvers: 'always',
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }

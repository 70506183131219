import {Injectable} from "@angular/core";
import * as AWS from "aws-sdk";
import {AWSError} from "aws-sdk/lib/error";
import {Request} from "aws-sdk/lib/request";
import S3, {PutObjectRequest,
  PutObjectOutput,
  DeleteObjectsRequest,
  ListObjectsV2Request,
  HeadObjectRequest,
  ObjectIdentifier,
  ObjectIdentifierList
} from 'aws-sdk/clients/s3';
import {S3_CONFIG} from "@app/core/constants/apis-list";

declare const Buffer: any;

@Injectable({
  providedIn: 'root'
})
export class AwsService {
  protected _s3Client: S3;

  constructor() {
    const awsConfig = {
      signatureVersion: 'v4',
      region: S3_CONFIG.region || '',
      accessKeyId: S3_CONFIG.accessKeyId || '',
      secretAccessKey: S3_CONFIG.secretAccessKey || '',
    };
    AWS.config.update(awsConfig);
    this._s3Client = new AWS.S3(awsConfig);
  }

  /*
  * The following method is used to get signed url for a given aws bucket
  * @param path: string
  * @param bucket: string
  * @returns Promise<string>
  * */
  public async getSignedUrl(
    path: string = '',
    bucket: string = S3_CONFIG.private || ''
  ): Promise<string> {
    if (!path) {
      return new Promise((resolve, reject) => {
        reject('Path is empty');
      });
    }
    return this._s3Client.getSignedUrl('getObject', {
      Bucket: bucket,
      Expires: 86400, // (24 hours)
      Key: this.getDecodedUrl(path),
    });
  }

  /*
  * The following method is used to delete object from bucket
  * @param path: string
  * @param bucket: string
  * @returns Promise
  * */
  public async deleteObject(
    path: string = '',
    bucket: string = S3_CONFIG.private || ''
  ): Promise<any> {
    if (path) {
      return this._s3Client.deleteObject({
        Bucket: bucket,
        Key: this.getDecodedUrl(path),
      }).promise();
    }
    return new Promise((resolve, reject) => {
      return reject({
        time: new Date(),
        retryable: false,
        code: 'THY-ERR-001',
        name: 'path-not-found',
        region: S3_CONFIG.region || '',
        message: 'Path not found',
      });
    });
  }

  /*
  * The following method is used to fetch the list of objects from bucket
  * @param path: string
  * @param bucket: string
  * @returns Promise
  * */
  public async getObjectsList(path: string, bucket: string = S3_CONFIG.private || ''): Promise<any> {
    const params: ListObjectsV2Request = {
      Bucket: bucket,
      Prefix: this.getDecodedUrl(path),
      //StartAfter: this.getDecodedUrl(path),
    };
    return this._s3Client.listObjectsV2(params)
      .promise();
  }

  /*
  * The following method is used to delete object from bucket
  * @param path: string
  * @param bucket: string
  * @returns Promise
  * */
  public async deleteObjects(
    keys: ObjectIdentifierList = [],
    bucket: string = S3_CONFIG.private || ''
  ): Promise<any> {
    if (keys?.length === 0) {
      return new Promise((resolve, reject) => {
        return reject({
          time: new Date(),
          retryable: false,
          code: 'THY-ERR-002',
          name: 'empty-keys-found',
          region: S3_CONFIG.region || '',
          message: 'Provide at least 1 key',
        });
      });
    }
    keys = keys.map((value: ObjectIdentifier) => {
      value.Key = this.getDecodedUrl(value.Key);
      return value;
    });
    const params: DeleteObjectsRequest = {
      Bucket: bucket,
      Delete: {
        Objects: keys
      }
    }
    return this._s3Client.deleteObjects(params).promise();
  }

  /*
  * The following method is used to check object is exist in the given bucket
  * @param path: string
  * @param bucket: string
  * @returns Promise
  * */
  public async checkObjectExist(path: string, bucket: string = S3_CONFIG.private || ''): Promise<any> {
    const params: HeadObjectRequest = {
      Bucket: bucket,
      Key: this.getDecodedUrl(path),
    };
    return this._s3Client.headObject(params)
      .promise();
  }

  /*
  * The following method is used to check object is exist in the given bucket
  * @param file: File
  * @param path: string
  * @param bucket: string
  * @returns Request<PutObjectOutput, AWSError>
  * */
  public putObjectRequest(
    file: File,
    path: string,
    bucket: string = S3_CONFIG.private || ''
  ): Request<PutObjectOutput, AWSError> {
    const params: PutObjectRequest = {
      Body: file,
      Bucket: bucket,
      ContentType: file.type,
      ContentDisposition: 'inline',
      Key: this.getDecodedUrl(path),
    };
    return this._s3Client.putObject(params);
  }

  public getDecodedUrl(path: string = ''): string {
    if (!path) {
      return '';
    }
    return decodeURIComponent(path.toString());
  }

  /*
  * The following method returns the instance of AWS S3 client
  * @returns S3
  * */
  public getAwsClient(): S3 {
    return this._s3Client;
  }
}

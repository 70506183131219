export const HOME = '/';
export const CRM = '/crm';
export const PROFILE = '/profile';
export const PROJECTS = '/projects';

export const DATASETS = '/datasets';
export const USER_MANAGEMENT = '/user-management';
export const WEB_CONFIGURATIONS = '/web-configurations';
export const HOME_PAGE_BASE_URL = `${WEB_CONFIGURATIONS}/home-page`;
export const TOOL_CONFIGURATIONS = '/tool-configurations';
export const RATES_BUNDLES = '/rates-bundles';
export const TAGS = `${TOOL_CONFIGURATIONS}/project-tags`;
export const TRANSACTION_INVOICE = '/transactions/invoices';
export const DATASET_TAGS = `${TOOL_CONFIGURATIONS}/dataset-tags`;
export const TESTIMONIALS = `${HOME_PAGE_BASE_URL}/testimonials`;

export const ABOUT_BASE_URL = `${WEB_CONFIGURATIONS}/about`;
export const ABOUT_EMPLOYEES = `${ABOUT_BASE_URL}/employees`;
export const ABOUT_FOUNDERS = `${ABOUT_BASE_URL}/founders`;

export const FAQ_BASE_URL = `${WEB_CONFIGURATIONS}/faqs`;
export const FAQ_QUESTIONS = `${FAQ_BASE_URL}/questions`;
export const FAQ_CATEGORIES = `${FAQ_BASE_URL}/categories`;

export const PRODUCTS = `${WEB_CONFIGURATIONS}/product`;

export const ALGORITHM_MANAGEMENT = '/algorithm-management';


// Http Headers Constants
export const TOKEN = 'kaust-cms-token';
export const DEFAULT_ACCESS_DENIED_MESSAGE = `You don't have permission to perform the required action.`;
export const DEFAULT_ERROR_MESSAGE = 'Server rejected your request. Please try again!';
export const ENCRYPTION_SECRET = 'iA9iU1xE1wA4bO3xJ8eL0tW9aG1lF2vF';

export const SEARCH = "search";
export const DEFAULT_SORT_KEY = "id";
export const DATASETS_IMAGES_SORTING = 'dataset_images.id';
export const DEFAULT_SORT_ORDER: "ASC" | "DESC" = "DESC";
export const NUMBER_RECORDS_PER_PAGE: number = 20;
export const DEFAULT_PAGE_INDEX: number = 1;
export const PAGE_SIZE_OPTIONS: number[] = [10, 20, 30, 40, 50];
export const DEFAULT_RECORD_SIZE: number = 10;

export const DEFAULT_LANGUAGE_CODE = 'en';

export const PERMISSION_READ: string = 'read';
export const ROLE_SUPER_ADMIN: string = 'super_admin';
export const ROLE_GLOBAL_ADMIN: string = 'global_admin';
export const ROLE_ADMIN: string = 'admin';
export const ROLES: any[] = [
  {
    title: 'Super Admin',
    value: ROLE_SUPER_ADMIN,
  }, {
    title: 'Global Admin',
    value: ROLE_GLOBAL_ADMIN,
  }, {
    title: 'Admin',
    value: ROLE_ADMIN,
  }, {
    title: 'Content Manager',
    value: 'content_manager',
  }, {
    title: 'Account Manager',
    value: 'account_manager',
  }, {
    title: 'Data Scientist',
    value: 'data_scientist',
  }, {
    title: 'Data Analyst',
    value: 'data_analyst',
  }
];

export const ALGORITHMS_CATEGORIES: any[] = [
  {
    name: 'Images / Videos',
    value: 'images',
  }, {
    name: 'Map',
    value: 'map',
  }
];

export const ALGORITHMS_TYPES: any[] = [
  {
    "value": 1,
    "name": "Object Detection",
  },
  {
    "value": 2,
    "name": "Image Classification",
  },
  {
    "value": 3,
    "name": "Keypoint Localization",
  },
  {
    "value": 4,
    "name": "Semantic Segmentation",
  },
  {
    "value": 5,
    "name": "Instance Segmentation",
  }
];

export const SUCCESS_STATUS = 200;
export const ERROR_FETCHING_STATUS = 210;
export const SUCCESS_STATUS_STRING = 'success';
export const DEFAULT_DEBOUNCE_TIME = 1000;

export const CKEDITOR_TOOLBAR = [
  'bold',
  'italic',
  'underline',
  '|',
  'alignment',
  '|',
  'numberedList',
  'bulletedList',
  '|',
  'outdent',
  'indent',
  '|',
  'link',
  'blockquote',
  'uploadImage',
  'insertTable',
  'mediaEmbed',
  '|',
  'undo',
  'redo']


export const CKEDITOR_SIMPLE_TOOLBAR = [
  'bold',
  'italic',
  'underline',
  '|',
  'alignment',
  '|',
  'numberedList',
  'bulletedList',
  '|',
  'link']

export const CKEDITOR_BASIC_TOOLBAR = [
  'bold',
  'italic',
  'underline',
  '|',
  'alignment',
  '|',
  'link']

export const WalletUsageType: any = {
  topup: 'Top Up',
  inference: 'Inference',
  training: 'Training',
  cloning: 'Cloning',
  earning: 'Earning',
  cashout: 'Cashout',
  free_bundle: 'Free Bundle',
  pro_bundle: 'Pro Bundle',
  custom_bundle: 'Custom Bundle',
  purchased: 'Purchased',
  wrongly_detected_image: 'Wrongly detected image'
}
export const nameRegex = /^([^0-9]*)$/;
export const noWhiteSpaceRegex = /^\S*$/;
export const emailRegex = "^[a-z0-9._%+-]{2,}@[a-z0-9.-]{2,}\\.[a-z]{2,4}$";
export const alphanumericRegex: string = `[a-zA-Z0-9 ]*`;

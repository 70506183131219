export const environment = {
  uat: false,
  staging: true,
  production: false,
  appVersion: process.env['KAUST_VERSION'],
  sentryDns: process.env['KAUST_SENTRY_DNS'],
  awsRegion: process.env['KAUST_AWS_REGION_STG_UAT'],
  awsAccessKeyId: process.env['KAUST_AWS_ACCESS_KEY_ID_STG_UAT'],
  awsSecretAccessKey: process.env['KAUST_AWS_ACCESS_KEY_SECRET_STG_UAT'],
};

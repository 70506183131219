import { Component, Injector, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { AppSettingConfig } from "@app/app-types";
import { AuthService } from "@app/core/services";

import { ADMIN_BASE_URL } from "@app/core/constants/apis-list";
import { NavigationEnd } from '@angular/router';
import { BaseComponent } from '@src/app/core/base/base.component';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent extends BaseComponent implements OnDestroy {
  public currentRoute = '';

  public isFaqCollapsed: boolean = true;
  public isBlogCollapsed: boolean = true;
  public isAboutCollapsed: boolean = true;
  public isHomePageCollapsed: boolean = true;
  public isWebConfigCollapsed: boolean = true;
  public isToolConfigCollapsed: boolean = true;
  public isTransactionCollapsed: boolean = true;
  public isRatesBundlesConfigCollapsed: boolean = true;

  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    injector: Injector,
    private readonly authService: AuthService,
  ) {
    super(injector);
    this.authService.userSubject.pipe(
      takeUntil(this._onDestroy$)
    ).subscribe((user: any) => {
      this.currentUser = user;
      this.routeEvent();
      const appTheme = this.sharedDataService.appConfigSource.value.theme;
      const userTheme = user?.preferences?.find((p: any) => p.key === 'theme')
        ?.value;
      if (userTheme && appTheme !== userTheme) {
        this.setAppConfigByKeyValue('theme', userTheme);
      } else if (!userTheme && appTheme) {
        this.setUserPreference('theme', appTheme)
          .then();
      }
    });
  }

  override ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._unsubscribeToastrMessage$.next();
    this._unsubscribeToastrMessage$.complete();
  }

  updateAppConfigProperty(key: string, value: any): void {
    if (this.currentUser?.id) {
      if (key === 'theme') {
        this.setUserPreference(key, value)
          .then((bool: boolean) => {
            this.setAppConfigByKeyValue(key, value);
            setTimeout(() => {
              this.authService.updateUserPreference(key, value);
            }, 200);
          });
      } else {
        this.setAppConfigByKeyValue(key, value);
      }
    } else {
      this.setAppConfigByKeyValue(key, value);
    }
  }

  handleLogout(): void {
    this.authService.logout();
  }

  private setUserPreference(key: string, value: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!this.currentUser?.id) {
        return reject(false);
      }
      const body = {
        preferenceKey: key,
        preferenceValue: value
      };
      const url = `${ADMIN_BASE_URL}/${this.currentUser.id}/preference`;
      this.authService.requestEntity(
        "POST",
        url,
        body
      ).pipe(
        takeUntil(this._onDestroy$)
      ).subscribe({
        next: (res: any) => {
          return resolve(res?.status == 200);
        },
        error: err => (reject(false))
      });
    });
  }

  private setAppConfigByKeyValue(key: string, value: any): void {
    const appConfig: any = <AppSettingConfig>{ ...this.sharedDataService.appConfigSource.value };
    appConfig[key] = value;
    this.sharedDataService.setAppConfig(appConfig);
  }

  setTabActive(): boolean {
    return this.currentRoute.includes('/rates-bundles/pre-paid-bundles') || this.currentRoute.includes('/rates-bundles/customize-bundle/')
  }

  private routeEvent() {
    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeToastrMessage$),
      ).subscribe((event: any) => {
        if (event instanceof NavigationEnd && this.currentRoute !== event.url) {
          this.currentRoute = event.url;
        }
      });
  }

}

import {Injectable, OnDestroy} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate, NavigationExtras,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from "rxjs/operators";

import {AuthService} from '../services';

@Injectable()
export class CanActivateGuard implements CanActivate, OnDestroy {
  private _redirectTo: string | null = null;
  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this._onDestroy$)
      )
      .subscribe(params => {
        if (params['redirectTo']) {
          this._redirectTo = params['redirectTo'];
        }
      });
  }

  /**
   * The following checks if the access can be provided or not to the current user
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn()) {
      return true;
    }
    const extras: NavigationExtras = {queryParams: {redirectTo: this._redirectTo}, queryParamsHandling: "merge"};
    return this.router.navigate(['/auth/login'], extras)
      .then()
      .catch();
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}

'use strict';
import { AppConfig } from '@app/app.config';
const apiConfig = new AppConfig();
export const APP_ENVIRONMENT: string = apiConfig.config.environment;
export const SERVER_URL: string = apiConfig.config.apiUrl.backendUrl;
export const FE_URL: string = apiConfig.config.apiUrl.appUrl;
export const ADMIN_BASE_URL: string = `${SERVER_URL}admin`;
export const S3_CONFIG = apiConfig.config.s3BucketConfig || {};
export const GOOGLE_MAP_API_KEY: string = apiConfig.config.googleMapAPIKey;
export const ACCESS_TOKEN_MAP_BOX: string = apiConfig.config.accessTokenMapBox;
export const STYLE_MAP_BOX_LIGHT: string = apiConfig.config.styleMapBoxLight;
export const STYLE_MAP_BOX_BLACK: string = apiConfig.config.styleMapBoxBlack;

export const AUTH_BASE_URL: string = `${ADMIN_BASE_URL}/auth`;
export const RESET_PASSWORD: string = `${AUTH_BASE_URL}/reset-password`;
export const UPDATE_PASSWORD: string = `${AUTH_BASE_URL}/update-password`;
export const VERIFY_RESET_TOKEN: string = `${AUTH_BASE_URL}/verify-reset-password-link`;

export const TAG_BASE_URL: string = `${ADMIN_BASE_URL}/tags`;
export const USER_BASE_URL: string = `${ADMIN_BASE_URL}/users`;
export const DATASET_PUBLIC_BASE_URL: string = `${SERVER_URL}datasets`;
export const DATASETS_ANNOTATION_BASE_URL: string = `${DATASET_PUBLIC_BASE_URL}/annotation`;
export const DATASETS_LABEL_BASE_URL: string = `${DATASETS_ANNOTATION_BASE_URL}/labels`;
export const CUSTOMER_BASE_URL: string = `${ADMIN_BASE_URL}/customers`;
export const DATASET_TAG_BASE_URL: string = `${ADMIN_BASE_URL}/tags`;

export const CONFIGURATION_BASE_URL: string = `${ADMIN_BASE_URL}/config/`;

export const CUSTOMER_DATASET_BASE_URL: string = `${CUSTOMER_BASE_URL}/datasets`;
export const CUSTOMER_PROJECT_BASE_URL: string = `${CUSTOMER_BASE_URL}/projects`;
export const TESTIMONIALS_BASE_URL: string = `${ADMIN_BASE_URL}/testimonials`;

export const PROJECTS_BASE_URL: string = `${SERVER_URL}projects`;
export const PROJECTS_ADVANCE_MAPPING_DETAILS_URL: string = `${PROJECTS_BASE_URL}/{id}/advance-mapping`;

export const BLOG_TAG_BASE_URL: string = `${ADMIN_BASE_URL}/blog/tags`;
export const BLOG_ARTICLE_BASE_URL: string = `${SERVER_URL}cms/blogs`;
export const BLOG_AUTHOR_BASE_URL: string = `${SERVER_URL}cms/blogs/author`;
export const ABOUT_FOUNDERS_BASE_URL: string = `${SERVER_URL}company/team/founders`;
export const ABOUT_EMPLOYEES_BASE_URL: string = `${SERVER_URL}company/team/employees`;
export const FAQ_CATEGORIES: string = `${ADMIN_BASE_URL}/faq-categories`;

export const FAQ_QUESTIONS: string = `${ADMIN_BASE_URL}/faqs`;

export const LANDING_PAGE_BASE_URL: string = `${SERVER_URL}landing-page`;
export const DATASET_CATEGORIES_BASE_URL: string = `${LANDING_PAGE_BASE_URL}/dataset-categories`;

export const HERO_IMAGE_BASE_URL: string = `${LANDING_PAGE_BASE_URL}/hero-image`;

/**
 * Datasets images
 */
export const DATASETS_IMAGES_BASE_URL: string = `${DATASET_PUBLIC_BASE_URL}/{id}/images`;

/**
 * Product APIS configuration base url
 */
export const PRODUCT_BASE_URL: string = `${LANDING_PAGE_BASE_URL}/product`;

/**
 * About intro configuration
 */
export const ABOUT_BASE_URL: string = `${CONFIGURATION_BASE_URL}about`;
export const ABOUT_INTRO: string = `${ABOUT_BASE_URL}/intro`;

export const PROJECT_LOGS: string = `${ADMIN_BASE_URL}/task/{id}`;

/**
 * Credits APIs configuration
 */
export const TOP_UP_BASE_URL: string = `${SERVER_URL}top-up`;

// API to get the balance value of a user (GET) & to topup the user balance(POST)
export const USER_TOP_UP_BASE_URL: string = `${SERVER_URL}top-up/user/{id}`;
//  here 1 is th default rate as BE told that this is going to be hardcoded for now as we have just one rate
export const RATES_BASE_URL: string = `${SERVER_URL}admin/rates/{id}`;

export const INFERENCING_LISTING: string = `${USER_BASE_URL}/inferences/all`

/**
 * BUNDLES APIs configuration
 */
export const BUNDLES_SYSTEM: string = `${SERVER_URL}admin/bundles/system`;
export const BUNDLES: string = `${SERVER_URL}admin/bundles`;
export const ASSIGNED_BUNDLES: string = `${SERVER_URL}admin/users/assigned-bundle-names`;

/**
 * DASHBOARD APIs configuration
 */
export const DASHBOARD_STATS: string = `${SERVER_URL}dashboard/stats/`;

/**
 * Dataset Health Check APIs configuration
 */
 export const DATASETS_STATS: string = `${SERVER_URL}datasets/health-check/`;


 /**
  * algorithms management
  */
 export const ALGORITHMS_MANAGEMENT: string = `${SERVER_URL}algorithm`;
 export const ALGORITHMS_TYPES: string = `${SERVER_URL}algorithm/types`;
 export const ALGORITHMS_HARDWARE_TYPES: string = `${SERVER_URL}hardware/all`;
 export const RELOAD_ALGORITHM: string = `${SERVER_URL}algorithm/{id}/reload`;
 export const GET_ALL_ALGO_TYPE_LIST_BY_ID: string = `${SERVER_URL}algorithm/all`;

/**
 * aws bucket apis
 */
  export const GET_AWS_SIGNED_URL_FETCH_IMG: string = `${SERVER_URL}s3/get-signed-url`;
  export const PATCH_AWS_SIGNED_URL_IMG: string = `${SERVER_URL}s3/put-signed-url/{id}`;
  export const DELETE_AWS_SIGNED_URL_PATCH_IMG: string = `${SERVER_URL}s3/delete-signed-url/{id}`;
  export const GET_IS_FILE_EXIST: string = `${SERVER_URL}s3/is-file-exist`;
  export const DELETE_FILE: string = `${SERVER_URL}s3/delete-file`;
  export const DELETE_BULK_FILE: string = `${SERVER_URL}s3/delete-bulk-files`;
  export const GET_LIST_OBJECTS: string = `${SERVER_URL}s3/list-Objects`;

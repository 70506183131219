import { tap } from 'rxjs/operators';
import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {LocalStorageService, SharedDataService} from '../services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  protected whiteListAPI: string[] = [];

  constructor(private router: Router,
              private localService: LocalStorageService,
              private sharedDataService: SharedDataService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const langCode = this.localService.getDataInLocalStorage('kaust-cms-language')?.code?.toLowerCase() || 'en';
    request = request.clone({
      setHeaders: {
        'x-locale': langCode,
      }
    });
    // Checking token only for Auth apis
    const isTokenRequired = !request.url.toLowerCase().includes('/auth/');
    if (isTokenRequired) {
      const token = this.localService.getToken();
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          }
        });
      }
    }
    return next.handle(request)
      .pipe(tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // if any response says, token not provided
            if (event.status == 401 || event.body?.status == 401) {
              this.handleLogout();
            }
          }
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            this.sharedDataService.showLoadingBar(false);
            if (err.status === 401) {
              this.handleLogout();
            }
          }
        }
      ));
  }

  private handleLogout(): void {
    this.sharedDataService.showLoadingBar(false);
    this.localService.clearDataInLocalStorage();
    this.router.navigateByUrl('/auth/login').then();
  }
}

import { Injectable, Injector } from '@angular/core';
import { throwError, BehaviorSubject } from 'rxjs';
import { map, shareReplay, tap, catchError } from 'rxjs/operators';

import { HttpService } from "@app/core/services/http.service";

import {AUTH_BASE_URL} from '@app/core/constants/apis-list';
import {trimValue, getLastModifiedImage} from "@app/core/shared/helpers";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends HttpService {
  private errors = [];
  public loggedIn = false;
  public userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(injector: Injector) {
    super(injector);
    this.setUserDetails();
  }

  setUserDetails(user: any = this.localService.get('kaust-cms-user')): void {
    this.user = user;
    this.userSubject.next(user);
  }

  login(body: { email: any, password: any }) {
    return this.requestEntity(
      'POST',
      AUTH_BASE_URL + '/login',
      body,
    )
      .pipe(
        map((response: any) => {
          if ((response.status as any) === this.constantList.SUCCESS_STATUS) {
            if (!response.body) {
              this.showMessage(`User doesn't exists!`, 'warning');
            }
            const role = response.body.role;
            if (!role) {
              this.showMessage('Invalid role', 'warning');
              return null;
            }
            const hasValidRole = role && this.constantList.ROLES.map(r => r.value)
              .indexOf(role) > -1;
            if (!hasValidRole) {
              this.showMessage('Invalid role', 'warning');
              return null;
            } else {
              return response.body;
            }
          }

          this.clearErrors();
          if (response.validation_errors) {
            this.handleErrorMessages(response);
          } else {
            this.showMessage(response.message);
          }
          return null;
        }),
        tap(authResult => {
          if (authResult) {
            this.setLocalSession(authResult as any);
            this.loggedIn = true;
            return authResult;
          }
          this.loggedIn = false;
          return null;
        }),
        shareReplay(1),
        catchError(err => throwError(() => this.handleErrorMessages(err)))
      );
  }

  logout() {
    this.user = null;
    this.userSubject.next(null);
    this.localService.clearDataInLocalStorage();
    window.location.replace('/auth/login');
  }

  public isLoggedIn(): boolean {
    return this.localService.getDataInLocalStorage(this.constantList.TOKEN) ? true : false;
  }

  isLoggedOut(): boolean {
    return !this.isLoggedIn();
  }

  getErrors() {
    return this.errors;
  }

  clearErrors() {
    this.errors = [];
  }

  public updateUserPreference(key: string, value: any): void {
    if (this.user?.id) {
      const preference = {
        id: null,
        key: key,
        value: value,
        userId: this.user.id
      };
      const preferenceIndex: number = (this.user.preferences || []).findIndex((p: any) => p.key === key);
      if (!this.user.preferences) {
        this.user.preferences = [];
      }
      if (preferenceIndex > -1) {
        if (this.user.preferences[preferenceIndex].id) {
          preference.id = this.user.preferences[preferenceIndex].id;
        }
        this.user.preferences[preferenceIndex] = preference;
      } else {
        this.user.preferences.push(preference);
      }
      this.localService.set(
        {
          key: 'kaust-cms-user',
          value: this.user
        }
      );
      this.userSubject.next(this.user);
    }
  }

  /**
   * the following method will store info in the local session
   * @param authResult
   */
  private setLocalSession(authResult: any) {
    if (authResult?.profilePicture) {
      authResult.profilePicture = getLastModifiedImage('lastModified', authResult.profilePicture, authResult.updatedAt);
    }
    trimValue(authResult.firstName);
    trimValue(authResult.lastName);
    this.localService.set({ key: 'kaust-cms-user', value: authResult });
    this.localService.set({ key: this.constantList.TOKEN, value: authResult.token });
    this.localService.set({ key: 'kaust-cms-permissions', value: authResult.permissions || [] });
    this.setUserDetails(authResult);
  }
}

import { Component, OnInit } from '@angular/core';
import {SharedDataService} from "@app/core/services";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(public sharedDataService: SharedDataService) { }

  ngOnInit(): void {
  }

}

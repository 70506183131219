import {S3_CONFIG} from "@app/core/constants/apis-list";

export function getLastModifiedImage(queryString: string, image: string, date?: any) {
  if (image) {
    if (image.includes('base64,')) {
      return image;
    }
    const awsHostList = [
      S3_CONFIG.public || '',
      S3_CONFIG.private || '',
      S3_CONFIG.cloudFrontCDN || '',
    ];
    const isValidAwsLink: boolean = awsHostList.some((l: string) => image.includes(l));
    if (isValidAwsLink) {
      const url = new URL(decodeURIComponent(image));
      const lastModified = (date ? new Date(date) : new Date()).getTime();
      return `${url.origin}${url.pathname}?${queryString}=${lastModified}`;
    }
    return image;
  }
  return image;
}

import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {LayoutModule} from "@layout/layout.module";
import {AuthGuard, CanActivateGuard} from './core/guards';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./layout/layout.module')
      .then(mod => mod.LayoutModule),
    canActivate: [CanActivateGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module')
      .then(mod => mod.AuthModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    LayoutModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ]
})
export class AppRoutingModule { }

import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import {AppSettingConfig} from "@app/app-types";
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  public loadingBarSource = new BehaviorSubject(false);
  loadingBarStatus = this.loadingBarSource.asObservable();
  public loadingBarSourceReset = new BehaviorSubject(false);
  private formSubmitButtonSource = new BehaviorSubject(false);
  public appConfigSource: BehaviorSubject<AppSettingConfig> = new BehaviorSubject<AppSettingConfig>(this.getDefaultLanguage());
  appConfig$ = this.appConfigSource.asObservable();

  public localService: LocalStorageService;

  constructor(injector: Injector) {
    this.localService = injector.get(LocalStorageService);
    let config = this.localService.get('kaust-cms-app-config') || null;
    if (!config) {
      config = this.getDefaultAppConfig()
    }
    this.appConfigSource.next(config);
    const user = this.localService.getDataInLocalStorage('kaust-cms-user');
    if (user?.language) {
      // Setting user language
      this.setUserLanguage(user.language);
    }
  }

  /**
   * the following method is used to emit for loading bar
   * @param status
   */
  showLoadingBar(status: boolean) {
    setTimeout(() => {
      this.loadingBarSource.next(status);
    }, 100);
  }

  /**
   * the following method is used to emit for loading bar
   * @param submit
   */
  changeFormSubmitStatus(submit: boolean) {
    setTimeout(() => {
      this.formSubmitButtonSource.next(submit);
    }, 100);
  }

  /**
   * the following method is used to set the app theme mode
   * @param config
   */
  setAppConfig(config: AppSettingConfig = this.getDefaultAppConfig()): void {
    if (JSON.stringify(this.appConfigSource.value) !== JSON.stringify(config)) {
      this.appConfigSource.next(config);
      this.localService.set(
        {
          key: 'kaust-cms-app-config',
          value: config,
        }
      );
    }
  }

  getDefaultAppConfig(): any {
    return <AppSettingConfig>{
      locale: 'en',
      theme: 'light',
      layoutDirection: 'ltr',
      isSideBarCollapse: false,
      language: this.getDefaultLanguage(),
    };
  }

  /**
   * the following method is used to set user language
   * @param language
   */
  setUserLanguage(language: any = null): void {
    if (language?.code && ['en', 'ar'].indexOf(language.code.toLowerCase()) === -1) {
      language = this.getDefaultLanguage();
    }
    const appConfig = {...this.appConfigSource.value};
    appConfig.language = language;
    appConfig.locale = language.code;
    this.setAppConfig(appConfig);
  }

  private getDefaultLanguage(): any {
    return {
      id: null,
      code: 'en'
    };
  }
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {DEFAULT_LANGUAGE_CODE} from '../constants/constant-list';

export interface Locale {
  lang: string;
  data: Object;
}

@Injectable({
  providedIn: 'root'
})
export class TranslationLoaderService {
  constructor(private translate: TranslateService) {}

  public loadTranslations(...args: Locale[]): void {
    const locales = [...args];
    locales.forEach((locale) => {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translate.setTranslation(locale.lang, locale.data, true);
    });
    // this.translate.setDefaultLang("en");
    // this.translate.use();
  }

  setDefaultLanguage(locale: string = DEFAULT_LANGUAGE_CODE.toLowerCase()): void {
    this.translate.setDefaultLang(locale);
    this.translate.use(locale);
  }

  getTranslateInstance() {
    return this.translate;
  }
}

import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";

import { AppConfig } from './app.config';
import { environment } from '../environments/environment';

const apiConfig = new AppConfig();
Sentry.init({
  dsn: environment.sentryDns,
  environment: apiConfig.config.environment,
  integrations: [
    new BrowserTracing({
      tracingOrigins: [
        apiConfig.config.apiUrl.appUrl,
        apiConfig.config.apiUrl.backendUrl
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  maxBreadcrumbs: 50,
  debug: false,
  tracesSampleRate: 1.0,
});

@NgModule({
  imports: [],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    }
  ]
})

export class SentryConfigModule { }

import {
  Injectable, Injector, OnDestroy
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as ROUTE_LIST from '../constants/routes';
import * as API_LIST from '../constants/apis-list';
import * as CONSTANT_LIST from '../constants/constant-list';

import { BaseModel } from './BaseModel';
import { HttpService, LocalStorageService, SharedDataService, HelperService } from '../services';
import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import {CKEDITOR_SIMPLE_TOOLBAR} from "../constants/constant-list";

@Injectable()
export abstract class BaseComponent implements OnDestroy {
  public apiList = API_LIST;
  public routeList = ROUTE_LIST;
  public constantList = CONSTANT_LIST;

  public activatedRoute: ActivatedRoute;

  public httpService: HttpService;
  public helperService: HelperService;
  public sharedDataService: SharedDataService;
  public localStorageService: LocalStorageService;
  public modalService: NgbModal;
  public router: Router;
  public currentUser: any;
  public baseModel: any = new BaseModel();
  public editorConfig = {
    toolbar: CKEDITOR_SIMPLE_TOOLBAR,
  }
  public editorArabicConfig = {
    toolbar: CKEDITOR_SIMPLE_TOOLBAR,
    language : {
      ui: 'ar',
      content: 'ar'
    },
  }
  /**
   * the following is used to keep the page id based on for edit/detail/view screen
   */
  public pageIdParam: any = 'id';

  public dataSubscription$ !: Subscription;
  public updateUserSubscription$ !: Subscription;
  public dropdownListSubscription$: Subscription[] = [];

  /*
  * The following is used to hold the translation message instance
  * */
  protected _unsubscribeToastrMessage$: Subject<void> = new Subject<void>();

  constructor(injector: Injector) {
    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.modalService = injector.get(NgbModal);
    this.httpService = injector.get(HttpService);
    this.helperService = injector.get(HelperService);
    this.sharedDataService = injector.get(SharedDataService);
    this.localStorageService = injector.get(LocalStorageService);
  }

  ngOnDestroy() {
    this._unsubscribeToastrMessage$.next();
    this._unsubscribeToastrMessage$.complete();
    if (this.dataSubscription$) {
      this.dataSubscription$.unsubscribe();
    }
    if (this.updateUserSubscription$) {
      this.updateUserSubscription$.unsubscribe();
    }
    if (this.dropdownListSubscription$.length > 0) {
      this.dropdownListSubscription$.forEach((s: Subscription) => {
        s.unsubscribe();
      });
    }
  }

  goToRoute(link: string): void {
    this.router.navigateByUrl(link);
  }

  isLogged() {
    return !!this.localStorageService.getToken();
  }

  getCurrentUser() {
    return this.localStorageService.getDataInLocalStorage('kaust-cms-user');
  }

  logout(): any {
    this.localStorageService.removeDataInLocalStorage(`${this.constantList.TOKEN}`);
    this.localStorageService.removeDataInLocalStorage('kaust-cms-user');
    this.goToRoute('/');
  }

  /**
   * The following check if its edit mode
   * @returns {string | null}
   */
  isEditMode(param: string = this.pageIdParam): string | null {
    return this.activatedRoute.snapshot.paramMap.get(param);
  }

  ckEditorMaxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!maxLength || (value === null) || (value === '')){
        return  null;
      }
        else {
        const isValid =  ( this.ckeditorTextLength(value) <= maxLength);
        return !isValid ? { maxlength: true } : null;
      }
    };
  }

  ckeditorTextLength(text: string){
    if(text){
      let stringValue =  text.replace( /(<([^>]+)>)/ig, '');
      stringValue =  stringValue.replace(/&nbsp;/g, ' ');
      return stringValue.length;
    }
    else {
      return 0
    }
  }

  textAreaMaxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!maxLength || (value === null) || (value === '')){
        return  null;
      }
      else {
        const isValid =  ( this.textAreaLength(value) <= maxLength);
        return !isValid ? { maxlength: {
            requiredLength: maxLength
          } } : null;
      }
    };
  }

  textAreaLength(text: string){
    if(text){
      const stringValue =  text?.replace(/\r\n/g, " ");
      return stringValue.length;
    }
    else {
      return 0
    }
  }
  getUniqueListBy(arr: any, key: any) {
    return [...new Map(arr.map((item: any) => [item[key], item])).values()]
  }
}


import { NgModule } from '@angular/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import {NgbCollapseModule} from "@ng-bootstrap/ng-bootstrap";

import { SideBarComponent } from './side-bar.component';
import {SharedModule} from "@app/core/shared/shared.module";
import {TooltipModule} from "ngx-bootstrap/tooltip";

@NgModule({
  declarations: [
    SideBarComponent
  ],
  exports: [
    SideBarComponent
  ],
  imports: [
    SharedModule,
    LazyLoadImageModule,
    NgbCollapseModule,
    TooltipModule,
  ]
})
export class SideBarModule { }

import {Injectable} from '@angular/core';

import * as CONSTANT_LIST from '../constants/constant-list';
import {LocalStorageService} from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private constantList = CONSTANT_LIST;
  private permissions: any[] = [];
  private user: any = null;

  constructor(private localService: LocalStorageService) {
    this.user = this.localService.get('kaust-cms-user');
    if (this.localService.get(this.constantList.TOKEN)) {
      this.permissions = this.localService.get('kaust-cms-permissions') || [];
    }
  }

  private validRole(): boolean {
    if (!this.hasValidRole()) {
      return false;
    }

    const role: string | null = this.user.role;
    if (!role) {
      return false;
    }
    const index: number = this.constantList.ROLES.map((r: any) => {
      return r.value;
    }).indexOf(role);

    return index !== -1;
  }

  checkModulePermission(moduleName: string, permission: string = this.constantList.PERMISSION_READ): boolean {
    if (this.isSuperAdmin()) {
      return true;
    } else if (!this.permissions || !moduleName) {
      return false;
    }

    const perm = this.permissions.find(p => p.module == moduleName);
    if (!perm) {
      return false;
    }
    return perm[permission] || false;
  }

  canAccessModule(moduleName: string, permission: string = this.constantList.PERMISSION_READ): boolean {
    return this.checkModulePermission(moduleName, permission);
  }

  public isSuperAdmin(): boolean {
    if (!this.validRole()) {
      return false;
    }
    return this.hasValidRole() === this.constantList.ROLE_SUPER_ADMIN;
  }

  public hasRole(role: string): boolean {
    return this.hasValidRole() === role;
  }

  public hasRoles(roles: string[] = []): boolean {
    const userRole = this.hasValidRole();
    if (!userRole) {
      return false;
    }
    return roles.indexOf(userRole) > -1;
  }

  public revokePermissions(permissions: any[] = []): void {
    this.permissions = permissions;
    this.localService.set({
      key: 'kaust-cms-permissions',
      value: permissions
    });
  }

  private hasValidRole(): any {
    return this.user?.role || null;
  }
}

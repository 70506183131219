import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';

import { BaseNetworkService } from './base-network.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService extends BaseNetworkService {
  constructor(private injector: Injector) {
    super(injector);
  }

  public requestEntity(
    method: string,
    apiLink: string,
    body: any = {},
    headers: HttpHeaders = this.formDataHeaders,
    showMessage: boolean = true
  ) {
    return this.http.request(
      method,
      apiLink,
      {
        body,
        headers,
        params: method === 'GET' && Object.keys(body).length > 0 ? new HttpParams({fromObject: body}) : {},
      }
    )
      .pipe(
        catchError((e: any) => {
          if (showMessage) {
            this.handleErrorMessages(e.error);
          }
          return throwError(() => e.error || '');
        }),
        map((response: any) => {
          try {
            const isError = (response?.status >= 400 && response?.status <= 500);
            if (showMessage && response?.message && response?.message?.toLowerCase() !== 'success') {
              this.showMessage(response.message, isError ? 'error' : 'success');
            }
            return response;
          } catch (e) {
            return response;
          }
        }),
      );
  }
}

import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ToastrService} from "ngx-toastr";

import * as CONSTANT_LIST from '../constants/constant-list';

import { LocalStorageService } from './local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class BaseNetworkService {
  public localService: LocalStorageService;
  protected router: Router;
  protected http: HttpClient;
  protected toastrService: ToastrService;

  protected constantList = CONSTANT_LIST;
  public user: any = null;
  private _headers: HttpHeaders = new HttpHeaders();
  private _formDataHeaders: HttpHeaders = new HttpHeaders();
  private _multipartFormDataHeaders: any;

  constructor(injector: Injector) {
    this.router = injector.get(Router);
    this.http = injector.get(HttpClient);
    this.localService = injector.get(LocalStorageService);
    this.toastrService = injector.get(ToastrService);

    this.initHeaders();
    this.initFormDataHeaders();
    this.initMultiPartFormDataHeaders();
  }

  initHeaders(): void {
    const token = this.localService.getToken();
    this._headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Accept', '*/*');
    if (token) {
      this._headers = this._headers.set('Authorization', `Bearer ${token}`);
    }
  }

  initFormDataHeaders(): void {
    const token = this.localService.getToken();
    this._formDataHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');
    if (token) {
      this._formDataHeaders = this._formDataHeaders.set('Authorization', `Bearer ${token}`);
    }
  }

  initMultiPartFormDataHeaders(): void {
    // HttpHeader class are immutable objects.
    const token = this.localService.getToken();
    this._multipartFormDataHeaders = {
      //'Content-Type': 'undefined',
      'Accept': '*/*',
    };
    if (token) {
      this._multipartFormDataHeaders['Authorization'] = `Bearer ${token}`;
    }
  }

  get headers(): HttpHeaders {
    if (!this._headers) {
      this.initHeaders();
    }
    return this._headers;
  }

  set headers(value: HttpHeaders) {
    this._headers = value;
  }

  get formDataHeaders(): HttpHeaders {
    if (!this._formDataHeaders) {
      this.initFormDataHeaders();
    }
    return this._formDataHeaders;
  }

  set formDataHeaders(value: HttpHeaders) {
    this._formDataHeaders = value;
  }

  get multiPartFormDataHeaders(): any {
    if (!this._multipartFormDataHeaders) {
      this.initMultiPartFormDataHeaders();
    }

    return this._multipartFormDataHeaders;
  }

  parseResponse(json: any): any {
    return json as any;
  }

  /**
   * The following method is used to get the error messages from the response
   * @_param json
   * @_returns {any}
   */
  getErrorMessages(json: any): any {
    return json?.message || '';
  }

  handleErrorMessages(json: any) {
    const errors = this.getErrorMessages(json);
    if (errors) {
      this.showMessage(Array.isArray(errors) ? errors[0] : errors);
    }
  }

  showMessage(msg: string, type = 'error'): void {
    switch (type) {
      case 'error':
        this.toastrService.error(msg);
        break;
      case 'warning':
        this.toastrService.warning(msg);
        break;
      case 'info':
        this.toastrService.info(msg);
        break;
      default:
        this.toastrService.success(msg);
        break;
    }
  }

  rejectErrorMessages(errorData: any, reject: any) {
    this.getErrorMessages(errorData.error).then((errorsArray: any) => {
      if (errorsArray) {
        reject(errorsArray);
      }
    });
  }
}

import {Injectable, OnDestroy} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

import {AuthService} from '../services';

@Injectable()
export class AuthGuard implements CanActivate, OnDestroy {
  private _redirectTo: string = '/';
  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
  ) {
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this._onDestroy$)
      )
      .subscribe(params => {
        if (params['redirectTo']) {
          this._redirectTo = params['redirectTo'];
        }
      });
  }

  /**
   * The following checks if the access can be provided or not to the current user
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn()) {
      return this.router.navigateByUrl(this._redirectTo)
        .then()
        .catch();
    }
    return true;
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}

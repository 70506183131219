import {Injectable} from "@angular/core";
import {S3_CONFIG} from "@app/core/constants/apis-list";
import { Observable, Subject } from "rxjs";
import { HttpService } from "./http.service";
import * as API_LIST from '../constants/apis-list';
import { takeUntil } from "rxjs/operators";
declare const Buffer: any;

@Injectable({
  providedIn: 'root'
})
export class AwsService {
  public apiList = API_LIST;
  private readonly _onDestroy$: Subject<void> = new Subject<void>();

  constructor(private readonly httpService: HttpService) {

  }

  /*
  * The following method is used to get signed url for a given aws bucket
  * @param path: string
  * @param bucket: string
  * @returns Promise<string>
  * */

  public getSignedUrl(path: string = ''): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!path) {
        reject('Path is empty');
        return;
      }
      const pathDecode = this.getDecodedUrl(path).split('/');
      const signedUrl = `${this.apiList.GET_AWS_SIGNED_URL_FETCH_IMG}/${pathDecode[1]}?resourceName=${pathDecode[0]}&fileName=${pathDecode[2]}`;
      this.httpService.requestEntity("GET", signedUrl)
        .pipe(takeUntil(this._onDestroy$))
        .subscribe({
          next: (response: any) => {
            if (response?.body?.url) {
              resolve(response.body.url);
            } else {
              reject('No URL in response body');
            }
          },
          error: (error: any) => {
            console.error(error);
            reject(error);
          }
        });
    });
  }

  /*
  * The following method is used to delete object from bucket
  * @param path: string
  * @param bucket: string
  * @returns Promise
  * */
  public async deleteObject(path: string = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      if (path) {
        const params = path.split('/');
        this.httpService.requestEntity('Delete',
          `${this.apiList.DELETE_FILE}/${params[1]}?resourceName=${params[0]}&fileName=${params[2]}`).pipe(
            takeUntil(this._onDestroy$)).subscribe({
              next: (res: any) => {
                console.log(res);
              },
              error: (err: any) => {
                console.log(err);
              }
            })
      }
      return reject({
        time: new Date(),
        retryable: false,
        code: 'THY-ERR-001',
        name: 'path-not-found',
        message: 'Path not found',
      });
    });
  }

  /*
  * The following method is used to fetch the list of objects from bucket
  * @param path: string
  * @param bucket: string
  * @returns Promise
  * */
  public async getObjectsList(
    path: string,
  ): Promise<any> {
    const params: any = this.getDecodedUrl(path).split('/');
    try {
      const response: any = await this.httpService
        .requestEntity('GET', `${API_LIST.GET_LIST_OBJECTS}/${params[1]}?resourceName=${params[0]}`)
        .pipe(takeUntil(this._onDestroy$))
        .toPromise();

      return response?.body;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  /*
  * The following method is used to delete object from bucket
  * @param path: string
  * @param bucket: string
  * @returns Promise
  * */
  public async deleteObjects(keys: any = []): Promise<boolean> {
    if (keys?.length === 0) {
      return Promise.reject({
        time: new Date(),
        retryable: false,
        code: 'THY-ERR-002',
        name: 'empty-keys-found',
        message: 'Provide at least 1 key',
      });
    }
    let params = '';
    let images: string[] = [];
    // Extract parameters
    if (Array.isArray(keys)) {
      params = keys[0].Key.split('/')
      images = keys.map((value: any) => this.getDecodedUrl(value.Key.split('/').pop()));
    }
    else{
      params = keys.Key.split('/')
      images = [this.getDecodedUrl(keys.Key.split('/').pop())]
    }


    // Prepare the request payload
    const payload = {
      resourceName: params[0],
      images: images,
    };

    // Return a Promise for the HTTP request
    return new Promise((resolve, reject) => {
      this.httpService
        .requestEntity('DELETE', `${this.apiList.DELETE_BULK_FILE}/${params[1]}`, payload)
        .pipe(takeUntil(this._onDestroy$))
        .subscribe({
          next: (res: any) => {
            if (res?.body) {
              resolve(true); // Resolve promise with true on success if `body` exists
            } else {
              reject(false); // Reject promise with false if `body` is missing
            }
          },
          error: (err: any) => {
            console.error('Delete failed:', err);
            reject(false); // Reject promise with false on failure
          },
        });
    });
  }

  /*
  * The following method is used to check object is exist in the given bucket
  * @param path: string
  * @param bucket: string
  * @returns Promise
  * */
  public async checkObjectExist(path: string, isPublic: string = 'no'): Promise<boolean> {
    const params = path.split('/');
    try {
      const response: any = await this.httpService
        .requestEntity(
          'GET',
          `${API_LIST.GET_IS_FILE_EXIST}/${params[1]}?resourceName=${params[0]}&fileName=${params[2]}&isPublic=${isPublic.toLocaleLowerCase()}`
        )
        .pipe(takeUntil(this._onDestroy$))
        .toPromise();

      return response?.body || false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  /*
  * The following method is used to check object is exist in the given bucket
  * @param file: File
  * @param path: string
  * @param bucket: string
  * @returns Request<PutObjectOutput, AWSError>
  * */
  public putObjectRequest(object: any, isPublic: boolean=false): Observable<any> {
    return new Observable((observer) => {
      // Validate input early
      if (!object || !object.Key || !object.Body) {
        observer.error('Invalid input: object must have Key and Body properties');
        return;
      }

      const params = object.Key.split('/');
      const resourceName = params[0];
      const datasetId = params[1];
      const imageKey = params[2];

      // Step 1: Fetch the signed URL from the backend
      this.httpService
        .requestEntity('POST', API_LIST.PATCH_AWS_SIGNED_URL_IMG.replace('{id}', datasetId), {
          resourceName: resourceName,
          images: [imageKey],
          isPublic: isPublic,
        })
        .subscribe({
          next: (res: any) => {
            const signedUrl = res.body?.response?.[0]?.signedurl;
            if (!signedUrl) {
              observer.error('Failed to get signed URL');
              return;
            }

            // Step 2: Use the signed URL to upload the file
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', signedUrl, true);
            xhr.setRequestHeader('Content-Type', object.ContentType || 'application/octet-stream');

            // Monitor upload progress
            xhr.upload.onprogress = (event: ProgressEvent) => {
              if (event.lengthComputable) {
                const progress = (event.loaded / event.total) * 100;
                observer.next(progress); // Notify progress
              }
            };

            // Handle upload completion
            xhr.onload = () => {
              if (xhr.status >= 200 && xhr.status < 300) {
                observer.complete(); // Notify completion
              } else {
                observer.error(`Upload failed with status ${xhr.status}`);
              }
            };

            // Handle errors
            xhr.onerror = () => {
              observer.error('An error occurred during the upload');
            };

            // Validate body type and send the request
            if (object.Body instanceof Blob || object.Body instanceof File) {
              xhr.send(object.Body);
            } else {
              observer.error('Invalid body type: must be Blob or File');
            }
          },
          error: (err) => {
            observer.error(`Failed to fetch signed URL: ${err.message}`);
          },
        });
    });
  }

  public getDecodedUrl(path: string = ''): string {
    if (!path) {
      return '';
    }
    return decodeURIComponent(path.toString());
  }

}

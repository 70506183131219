import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from "@angular/common";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

import { SharedDataService } from "@app/core/services";
import { AppSettingConfig } from "@app/app-types";

import * as en from '../assets/i18n/en.json';
import * as ar from '../assets/i18n/ar.json';
import { NavigationEnd, Event, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  template: `<main class="app-container">
    <router-outlet></router-outlet>
  </main>`,
})
export class AppComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<void> = new Subject<void>();
  currentRoute: string = "/";

  constructor(
    @Inject(DOCUMENT) private document: any,
    private translate: TranslateService,
    private router: Router,
    public sharedDataService: SharedDataService
  ) {
    this.translate.setTranslation('en', en, true);
    this.translate.setTranslation('ar', ar, true);
    this.sharedDataService.appConfig$
      .pipe(
        takeUntil(this._onDestroy$)
      ).subscribe((appConfig: AppSettingConfig) => {
        const code = appConfig?.locale?.toLowerCase() || 'en';
        if (code != this.translate.currentLang?.toLowerCase()) {
          this.translate.setDefaultLang(code);
          this.translate.use(code);
        }
        if (this.document.documentElement.lang?.toLowerCase() !== code) {
          this.document.documentElement.lang = code;
        }
        const layoutDirection = code == 'ar' ? 'rtl' : 'ltr';
        if (this.document.documentElement.dir?.toLowerCase()) {
          this.document.documentElement.dir = layoutDirection;
        }
        const hasArabicClass = this.document.body.classList.contains('ar-lang');
        if (layoutDirection === 'rtl') {
          if (this.document.body?.className?.indexOf('rtl-direction') === -1) {
            this.document.body?.classList.add('rtl-direction');
          }
        } else {
          this.document.body?.classList.remove('rtl-direction');
        }
        if (code === 'ar') {
          if (!hasArabicClass) {
            this.document.body.classList.add('ar-lang');
          }
        } else {
          if (hasArabicClass) {
            this.document.body.classList.remove('ar-lang');
          }
        }
      });
  }

  ngOnInit(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        if (this.currentRoute != event.url) {
          this.sharedDataService.loadingBarSourceReset.next(true);
          this.currentRoute = event.url;
        }
      }
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {LayoutComponent} from "@layout/layout.component";
import { LayoutRoutingModule } from '@layout/layout-routing.module';
import {SideBarModule} from "@components/side-bar/side-bar.module";


@NgModule({
  declarations: [
    LayoutComponent
  ],
  imports: [
    CommonModule,
    SideBarModule,
    LayoutRoutingModule,
  ]
})
export class LayoutModule { }

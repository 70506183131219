import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from "@angular/router";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr";

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard, CanActivateGuard } from "@app/core/guards";
import {
  AuthService,
  BaseNetworkService,
  HttpService,
  LocalStorageService,
  TranslationLoaderService
} from "@app/core/services";
import { DEFAULT_LANGUAGE_CODE } from "@app/core/constants/constant-list";
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { TimerInterceptor } from "@app/core/interceptors/timer.interceptor";
import { SentryConfigModule } from './sentry-config.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    NgbModule,
    AppRoutingModule,
    SentryConfigModule,
    TranslateModule.forRoot(
      {
        defaultLanguage: DEFAULT_LANGUAGE_CODE
      }
    ),
    ToastrModule.forRoot({
      preventDuplicates: true,
      autoDismiss: true,
      enableHtml: true,
      closeButton: true,
      maxOpened: 1,
      resetTimeoutOnDuplicate: true,
      progressBar: false,
      timeOut: 6000,
      positionClass: 'toast-top-center',
      disableTimeOut: false,
      toastClass: 'kaust-toastr',
      messageClass: 'kaust-toastr-text',
      tapToDismiss: true,
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimerInterceptor,
      multi: true
    },
    AuthGuard,
    CanActivateGuard,
    TranslationLoaderService,
    LocalStorageService,
    BaseNetworkService,
    HttpService,
    AuthService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfig {
  config = {
    environment: 'staging',
    name: 'Thya Technology',
    title: 'Thya Technology',
    googleMapAPIKey: 'AIzaSyBU5WAEM8JVyFa1Q00rDxcL1PMqf6n8xs8',
    accessTokenMapBox: 'pk.eyJ1IjoiYWRtaW4yMjIxOTU1NSIsImEiOiJjbHE2Y3J5MW4wczg3MmpwYWxxZDFuanl5In0.IdgYzRDCWidGizy_JiJX4Q',
    styleMapBoxLight: 'mapbox://styles/admin22219555/cm60k7rom004d01sf2cm7dsw5',
    styleMapBoxBlack: 'mapbox://styles/admin22219555/cm6t6qei9016v01pb1a30hby7',
    version: environment.appVersion,
    apiUrl: {
      appUrl: 'https://stg-cms.devcl.net/',
      backendUrl: 'https://stg-backend.devcl.net/api/v1/'
      // backendUrl: 'http://localhost:3000/api/v1/' // This is for local testing
    },
    s3BucketConfig: {
      signatureVersion: 'v4',
      public: 'devcl-stg-web-app-public-assets',
      cloudFrontCDN: 'pub-assets-stg.devcl.net',
      private: 'devcl-stg-web-app-private-assets',
    }
  };

  constructor() {
    if (environment.uat) {
      this.config.environment = 'uat';
      this.config.apiUrl.appUrl = 'https://uat-cms.devcl.net/';
      this.config.apiUrl.backendUrl = 'https://uat-backend.devcl.net/api/v1/';
      this.config.s3BucketConfig.public = 'devcl-uat-web-app-public-assets';
      this.config.s3BucketConfig.private = 'devcl-uat-web-app-private-assets';
      this.config.s3BucketConfig.cloudFrontCDN = 'pub-assets-uat.devcl.net';
      this.config.googleMapAPIKey= 'AIzaSyBU5WAEM8JVyFa1Q00rDxcL1PMqf6n8xs8';
      this.config.accessTokenMapBox= 'pk.eyJ1IjoiYWRtaW4yMjIxOTU1NSIsImEiOiJjbHE2Y3J5MW4wczg3MmpwYWxxZDFuanl5In0.IdgYzRDCWidGizy_JiJX4Q';
      this.config.styleMapBoxLight= 'mapbox://styles/admin22219555/cm60k7rom004d01sf2cm7dsw5';
      this.config.styleMapBoxBlack= 'mapbox://styles/admin22219555/cm6t6qei9016v01pb1a30hby7';
    }
    if (environment.production) {
      this.config.environment = 'production';
      this.config.apiUrl.appUrl = 'https://admin.thya-technology.com/';
      this.config.apiUrl.backendUrl = 'https://api.thya-technology.com/api/v1/';
      this.config.googleMapAPIKey= 'AIzaSyBU5WAEM8JVyFa1Q00rDxcL1PMqf6n8xs8';
      this.config.accessTokenMapBox= 'pk.eyJ1IjoiYWRtaW4yMjIxOTU1NSIsImEiOiJjbHE2Y3J5MW4wczg3MmpwYWxxZDFuanl5In0.IdgYzRDCWidGizy_JiJX4Q';
      this.config.styleMapBoxLight= 'mapbox://styles/admin22219555/cm60k7rom004d01sf2cm7dsw5';
      this.config.styleMapBoxBlack= 'mapbox://styles/admin22219555/cm6t6qei9016v01pb1a30hby7';
      Object.assign(this.config.s3BucketConfig, {
        public: 'thya-public-assets',
        private: 'thya-private-assets',
        cloudFrontCDN: 'pub-assets.thya-technology.com',
      });
    }
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";

import {
  LocalStorageService,
  PermissionService,
} from '../services';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
  ],
  providers: [
    LocalStorageService,
    PermissionService,
  ]
})
export class SharedModule {}

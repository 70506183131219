import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfig {
  config = {
    environment: 'staging',
    name: 'Thya Technology',
    title: 'Thya Technology',
    version: environment.appVersion,
    apiUrl: {
      appUrl: 'https://stg-cms.devcl.net/',
      backendUrl: 'https://stg-backend.devcl.net/api/v1/'
      // backendUrl: 'http://localhost:3000/api/v1/' // This is for local testing
    },
    s3BucketConfig: {
      signatureVersion: 'v4',
      region: environment.awsRegion,
      accessKeyId: environment.awsAccessKeyId,
      public: 'devcl-stg-web-app-public-assets',
      cloudFrontCDN: 'pub-assets-stg.devcl.net',
      private: 'devcl-stg-web-app-private-assets',
      secretAccessKey: environment.awsSecretAccessKey,
    }
  };

  constructor() {
    if (environment.uat) {
      this.config.environment = 'uat';
      this.config.apiUrl.appUrl = 'https://uat-cms.devcl.net/';
      this.config.apiUrl.backendUrl = 'https://uat-backend.devcl.net/api/v1/';
      this.config.s3BucketConfig.public = 'devcl-uat-web-app-public-assets';
      this.config.s3BucketConfig.private = 'devcl-uat-web-app-private-assets';
      this.config.s3BucketConfig.cloudFrontCDN = 'pub-assets-uat.devcl.net';
    }
    if (environment.production) {
      this.config.environment = 'production';
      this.config.apiUrl.appUrl = 'https://admin.thya-technology.com/';
      this.config.apiUrl.backendUrl = 'https://api.thya-technology.com/api/v1/';
      Object.assign(this.config.s3BucketConfig, {
        public: 'thya-public-assets',
        private: 'thya-private-assets',
        cloudFrontCDN: 'pub-assets.thya-technology.com',
      });
    }
  }
}
